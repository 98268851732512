import { type ProxyFormConfig } from "@/features"
import { InputSingleFile, InputText, InputTextarea } from "@/shared/ui"
import { h, ref } from "vue"
import { create as createTicket } from "@/entities/support"
import dayjs from 'dayjs'

const user = ref(JSON.parse(localStorage.getItem('user') || '{}'))
const mainTabFields = [
  {
    label: 'Your name',
    name: 'name',
    required: false,
    type: 'input',
    component: h(InputText, { disabled: true, value: user.value.first_name + ' ' + user.value.last_name })
  },
  {
    label: 'Your email',
    name: 'email',
    required: false,
    type: 'input',
    component: h(InputText, { disabled: true, value: user.value.email })
  },
  {
    label: 'Subject',
    name: 'subject',
    required: true,
    type: 'input',
    component: h(InputText, { maxlength: 255 })
  },
  {
    label: 'Problem description',
    name: 'description',
    required: true,
    type: 'input',
    component: h(InputTextarea, { placeholder: `Please describe the issue you're encountering in as much detail as possible. Include patient details, error messages, and any other relevant information that can help us resolve the issue for you`, autoSize : { minRows: 4, maxRows: 8 }, maxlength: 1000})
  },
  {
    label: 'Attach file',
    name: 'file',
    required: false,
    type: 'file',
      component: h(InputSingleFile, { accept: '.pdf,.jpg,.jpeg,.png,.pdf,.gif', text: 'File size up to 50 MB, format: PDF, PNG, GIF, JPEG, JPG' })
  },
]

const config : ProxyFormConfig = {
  tabs: [
    {
      name: 'Main',
      fields: mainTabFields
    },
  ],
  noTabs: true,
  api: {
    preload: async () => {
      user.value = JSON.parse(localStorage.getItem('user') || '{}')
      mainTabFields[0].component = h(InputText, { disabled: true, value: user.value.first_name + ' ' + user.value.last_name })
      mainTabFields[1].component = h(InputText, { disabled: true, value: user.value.email })
    },
    submit: async (payload : any) => {
      payload.page_url = window.location.href
      payload.tz_created_at = dayjs().format('YYYY-MM-DD\THH:mm:ssZ');
      return await createTicket(payload)
    },
  },
}
export default config