export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
export const parseError =  (msg: any) => {
  let messageText: string;
  if (typeof msg === 'string') {
    messageText = msg;
  } else if (msg?.response?.data?.message) {
    messageText = msg.response.data.message;
  } else if (msg?.response?.statusText) {
    messageText = msg.response.statusText;
  } else if (msg?.message) {
    messageText = msg.message;
  } else {
    messageText = 'Error';
  }
  return messageText;
}
export const parseGetParams = () => {
  const params : any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => {
      if(typeof prop === 'string') {
        return searchParams.get(prop)
      }
    }
  });
  return params;
}