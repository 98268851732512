import axios from "axios";
import type { AxiosInstance } from "axios";

export const http: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

http.interceptors.request.use(

  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers ? config.headers.Authorization = `Bearer ${token}` : config.headers = { Authorization: `Bearer ${token}` };
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);
