export { validationRules } from './rules'

export const defaultAuthFormProps = {
  title: {
    type: String,
    default: '',
  },
  forgot_password: {
    type: Boolean,
    default: true,
  },
  remember_me: {
    type: Boolean,
    default: false,
  },
  show_logo: {
    type: Boolean,
    default: true,
  },
  support_email: {
    type: String,
    default: 'support@rocketdoc.co.uk'
  },
  password_input: {
    type: Boolean,
    default: true
  },
  auth_type: {
    interface: ['Email', 'Phone'],
    default: 'Email'
  },
  resend_time: {
    type: Number,
    default: 30
  },
  code_to: {
    interface: ['Email', 'Phone'],
    default: 'Phone'
  },
  num_inputs: {
    type: Number,
    default: 6
  },
  user_type: {
    type: String,
    default: 'Admin'
  },
  captcha_enabled: {
    type: Boolean,
    default: true
  },
}