<template>
  <div class="footerLinks">

    <a href="https://thefunctionalgutclinic.com/website-privacy-policy/" target="_blank">Privacy Policy</a>
    <div class="divider"></div>
    <a href="https://thefunctionalgutclinic.com/terms-and-conditions/" target="_blank">Terms of use</a>
  </div>
</template>
<style lang="scss" scoped>
.footerLinks{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
  a{
    color: var(--color-primary);
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
  .divider{
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--color-primary);
  }
}
</style>