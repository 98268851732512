export const validationRules = (passwordValue : string, phoneValid : boolean) => {
  const passwordLength = 8
  return {
    password: [
      { required: true, message: 'Please enter your password', trigger: 'blur' },
      { pattern: /[A-Za-z]/, message: 'Password must contain letters' },
      { pattern: /[A-Z]/, message: 'Password must contain at least one uppercase letter' },
      { pattern: /[a-z]/, message: 'Password must contain at least one lowercase letter' },
      { pattern: /[0-9]/, message: 'Password must contain numbers' },
      { min: passwordLength, message: `Password must be at least ${passwordLength} characters long`, trigger: 'blur' },
    ],
    password_confirmation: [
      { required: false, trigger: 'submit'},
      { validator: async (_ : any, value : string) => {
        return Promise[passwordValue === value ? 'resolve' : 'reject']('Passwords do not match')
      } },
    ],
    phone: [
      { required: true, message: 'Please enter your phone', trigger: 'blur' },
      { validator: async () => {
        return Promise[ phoneValid === true ? 'resolve' : 'reject']('Please enter a valid phone number')
      } },
    ],
  }
};

export const defaultProps = {
  title: {
    type: String,
    default: '',
  },
  show_logo: {
    type: Boolean,
    default: true,
  },
  support_email: {
    type: String,
    default: 'support@rocketdoc.co.uk'
  },
  captcha_enabled: {
    type: Boolean,
    default: false,
  },
  showPhone: {
    type: Boolean,
    default: true,
  },
}