export const validationRules = (phoneValid? : boolean, serverErrors? : string[]) => ({
  email: [
    { required: true, message: 'Please enter your email address', trigger: 'blur' },
    { type: 'email', message: 'Please enter a valid email address', trigger: 'blur' },
    // { validator: async () => serverErrors?.includes('email') ? Promise.reject() : Promise.resolve()}
  ],
  password: [
    { required: true, message: 'Please enter your password', trigger: 'blur' },
    { min: 8, message: 'Password must be at least 8 characters long', trigger: 'blur' },
    // { validator: async () => serverErrors?.includes('password') ? Promise.reject('Incorrect email or password.') : Promise.resolve()}
  ],
  phone: [
    { required: true, message: 'Please enter your phone', trigger: 'blur' },
    { validator: async () => {
      return Promise[ phoneValid === true ? 'resolve' : 'reject']('Please enter a valid phone number')
    } },
    // { validator: async () => serverErrors?.includes('phone') ? Promise.reject() : Promise.resolve()}
  ],
  code: [
    { validator: async () => serverErrors?.includes('code') ? Promise.reject('The entered code is invalid or has expired') : Promise.resolve() },
  ],
});