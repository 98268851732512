import type { ProviderPayload } from "./types"

export const defaultRules = (tabs : ProviderPayload[], serverErrors : Record<string, string> | Record<string, string[]>, phoneValidation : Record<string, boolean>, imageValidation : Record<string, string>) => {
  const rules = {} as any
  tabs.forEach(tab => {
    tab.fields.forEach(input => {
      if(input.type !== 'component'){
        rules[input.name] = []
        if(input.type === 'phone'){
          rules[input.name].push({ validator: async (_ : any, value : string) => {
            if(!value && !input.required ) return Promise.resolve()
            if(value === '+44' && !input.required ) return Promise.resolve()
            return input.name && Promise[ phoneValidation[input.name] === true ? 'resolve' : 'reject']('Please enter a valid phone number')
          }})
        } else if (input.type === 'email') {
          rules[input.name].push({ type: 'email', message: 'Please enter a valid email address', trigger: 'blur' })
        } else if (input.type === 'file') {
          rules[input.name].push({ validator: async () => input.name && typeof imageValidation[input.name] === 'string' ? Promise.reject(imageValidation[input.name]) : Promise.resolve() })
        } 
        rules[input.name].push({ required: input.required, message: 'This field is required', trigger: 'blur' })
        if(serverErrors[input.name] && Array.isArray(serverErrors[input.name])){
          (serverErrors[input.name] as string[]).forEach(error => {
            rules[input.name].push({ validator: async () => input.name && error ? Promise.reject(error) : Promise.resolve()})
          });
        } else {
          rules[input.name].push({ validator: async () => input.name && serverErrors[input.name] ? Promise.reject(serverErrors[input.name]) : Promise.resolve()})
        }
      }
    })
  })
  return rules 
}