<template>
  <div>
    <slot></slot>
  </div>
</template>
<style scoped lang="scss">
:deep(p), 
:deep(h1) 
{
  margin-bottom: var(--spacing-md);
}
:deep(h1) 
{
  font-size: 32px;
  line-height: 32px;
}
:deep(p) 
{
  font-size: 14px;
  line-height: 14px;
}
</style>